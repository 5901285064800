// paper & background
$paper: #ffffff;

// primary
// $primaryLight: #eef2f6;
$primaryLight: rgb(238, 238, 238);
// $primaryMain: #2196f3;
// $primaryMain: rgb(180, 170, 130);
$primaryMain: rgb(85, 85, 85);
// $primaryDark: #1e88e5;
$primaryDark: rgb(15, 15, 15);
// $primary200: #90caf9;
// $primary200: rgb(180, 170, 130);
$primary200: rgb(85, 85, 85);
// $primary800: #1565c0;
$primary800: black;


// secondary
// $secondaryLight: #ede7f6;
$secondaryLight: rgb(238, 238, 238);
$secondaryMain: #673ab7;
// $secondaryDark: #5e35b1;
// $secondaryDark: rgb(180, 170, 130);
$secondaryDark: rgb(85, 85, 85);
$secondary200: #b39ddb;
$secondary800: #4527a0;

// success Colors
$successLight: #b9f6ca;
$success200: #69f0ae;
$successMain: #00e676;
$successDark: #00c853;

// error
$errorLight: #ef9a9a;
$errorMain: #f44336;
$errorDark: #c62828;

// orange
$orangeLight: #fbe9e7;
$orangeMain: #ffab91;
$orangeDark: #d84315;

// warning
$warningLight: #fff8e1;
$warningMain: #ffe57f;
$warningDark: #ffc107;

// grey
// $grey50: #F8FAFC;
$grey50: rgb(238, 238, 238);
// $grey100: #EEF2F6;
$grey100: rgb(238, 238, 238);
$grey200: #E3E8EF;
$grey300: #CDD5DF;
// $grey500: #697586;
$grey500: rgb(85, 85, 85);
$darkGrey500: rgb(238, 238, 238);
$grey600: #4B5565;
// $grey700: #364152;
$grey700: rgb(85, 85, 85);
$darkGrey700: rgb(238, 238, 238);
$grey900: #121926;
$darkGrey900: #FFFFFF;

// ==============================|| DARK THEME VARIANTS ||============================== //

// paper & background
$darkBackground: #1a223f; // level 3
$darkPaper: #000000; // level 4

// dark 800 & 900
$darkLevel1: #29314f; // level 1
$darkLevel2: #212946; // level 2

// primary dark
// $darkPrimaryLight: #eef2f6;
$darkPrimaryLight: rgb(238, 238, 238);
$darkPrimaryMain: #2196f3;
$darkPrimaryDark: #1e88e5;
// $darkPrimary200: #90caf9;
// $darkPrimary200: rgb(180, 170, 130);
$darkPrimary200: rgb(85, 85, 85);
// $darkPrimary800: #1565c0;
$darkPrimary800: black;


// secondary dark
$darkSecondaryLight: #d1c4e9;
$darkSecondaryMain: #7c4dff;
$darkSecondaryDark: #651fff;
$darkSecondary200: #b39ddb;
$darkSecondary800: #6200ea;

// text variants
$darkTextTitle: #d7dcec;
$darkTextPrimary: #bdc8f0;
$darkTextPrimaryDark: rgb(238, 238, 238);
$darkTextSecondary: #8492c4;

// ==============================|| JAVASCRIPT ||============================== //

:export {
    // paper & background
    paper: $paper;

    // primary
    primaryLight: $primaryLight;
    primary200: $primary200;
    primaryMain: $primaryMain;
    primaryDark: $primaryDark;
    primary800: $primary800;

    // secondary
    secondaryLight: $secondaryLight;
    secondary200: $secondary200;
    secondaryMain: $secondaryMain;
    secondaryDark: $secondaryDark;
    secondary800: $secondary800;

    // success
    successLight: $successLight;
    success200: $success200;
    successMain: $successMain;
    successDark: $successDark;

    // error
    errorLight: $errorLight;
    errorMain: $errorMain;
    errorDark: $errorDark;

    // orange
    orangeLight: $orangeLight;
    orangeMain: $orangeMain;
    orangeDark: $orangeDark;

    // warning
    warningLight: $warningLight;
    warningMain: $warningMain;
    warningDark: $warningDark;

    // grey
    grey50: $grey50;
    grey100: $grey100;
    grey200: $grey200;
    grey300: $grey300;
    grey500: $grey500;
    darkGrey500: $darkGrey500;
    grey600: $grey600;
    grey700: $grey700;
    darkGrey700: $darkGrey700;
    grey900: $grey900;
    darkGrey900: $darkGrey900;

    // ==============================|| DARK THEME VARIANTS ||============================== //

    // paper & background
    darkPaper: $darkPaper;
    darkBackground: $darkBackground;

    // dark 800 & 900
    darkLevel1: $darkLevel1;
    darkLevel2: $darkLevel2;

    // text variants
    darkTextTitle: $darkTextTitle;
    darkTextPrimary: $darkTextPrimary;
    darkTextPrimaryDark: $darkTextPrimaryDark;
    darkTextSecondary: $darkTextSecondary;

    // primary dark
    darkPrimaryLight: $darkPrimaryLight;
    darkPrimaryMain: $darkPrimaryMain;
    darkPrimaryDark: $darkPrimaryDark;
    darkPrimary200: $darkPrimary200;
    darkPrimary800: $darkPrimary800;

    // secondary dark
    darkSecondaryLight: $darkSecondaryLight;
    darkSecondaryMain: $darkSecondaryMain;
    darkSecondaryDark: $darkSecondaryDark;
    darkSecondary200: $darkSecondary200;
    darkSecondary800: $darkSecondary800;
}
