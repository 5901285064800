// color variants
@import 'themes-vars.module.scss';

// third-party
@import '~react-perfect-scrollbar/dist/css/styles.css';

// ==============================|| LIGHT BOX ||============================== //
.fullscreen .react-images__blanket {
    z-index: 1200;
}

// ==============================|| APEXCHART ||============================== //

.apexcharts-legend-series .apexcharts-legend-marker {
    margin-right: 8px;
}

// ==============================|| PERFECT SCROLLBAR ||============================== //

.scrollbar-container {
    .ps__rail-y {
        &:hover > .ps__thumb-y,
        &:focus > .ps__thumb-y,
        &.ps--clicking .ps__thumb-y {
            background-color: $grey500;
            width: 5px;
        }
    }
    .ps__thumb-y {
        background-color: $grey500;
        border-radius: 6px;
        width: 5px;
        right: 0;
    }
}

.scrollbar-container.ps,
.scrollbar-container > .ps {
    &.ps--active-y > .ps__rail-y {
        width: 5px;
        background-color: transparent !important;
        z-index: 999;
        &:hover,
        &.ps--clicking {
            width: 5px;
            background-color: transparent;
        }
    }
    &.ps--scrolling-y > .ps__rail-y,
    &.ps--scrolling-x > .ps__rail-x {
        opacity: 0.4;
        background-color: transparent;
    }
}

// ==============================|| ANIMATION KEYFRAMES ||============================== //

@keyframes wings {
    50% {
        transform: translateY(-40px);
    }
    100% {
        transform: translateY(0px);
    }
}

@keyframes blink {
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes bounce {
    0%,
    20%,
    53%,
    to {
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        transform: translateZ(0);
    }
    40%,
    43% {
        animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
        transform: translate3d(0, -5px, 0);
    }
    70% {
        animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
        transform: translate3d(0, -7px, 0);
    }
    80% {
        transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        transform: translateZ(0);
    }
    90% {
        transform: translate3d(0, -2px, 0);
    }
}

@keyframes slideY {
    0%,
    50%,
    100% {
        transform: translateY(0px);
    }
    25% {
        transform: translateY(-10px);
    }
    75% {
        transform: translateY(10px);
    }
}

@keyframes slideX {
    0%,
    50%,
    100% {
        transform: translateX(0px);
    }
    25% {
        transform: translateX(-10px);
    }
    75% {
        transform: translateX(10px);
    }
}

// @font-face {
//     font-family: 'calisga';
//     src: url('../../views/assets/fonts/calisga-calisga-400.ttf');
// }

@font-face {
    font-family: 'calisga';
    src: url('../../views/assets/fonts/BarlowCondensed-Bold.ttf');
}

// @font-face {
//     font-family: 'calisga';
//     src: url('../../views/assets/fonts/BarlowCondensed-Regular.ttf');
// }

@font-face {
    font-family: 'Barlow-tiktag';
    src: url('../../views/assets/fonts/BarlowCondensed-Bold.ttf');
}

@font-face {
    font-family: 'Barlow-tiktag-regular';
    src: url('../../views/assets/fonts/BarlowCondensed-Regular.ttf');
}

@font-face {
    font-family: 'BarlowCondensedMeduim';
    src: url('../../views/assets/fonts/BarlowCondensed-Regular.ttf');
}

.download-qr-code-button {
    background: black;
    color: white;
    min-width: 100px;
}

.download-qr-code-button:hover {
    color: rgb(180, 170, 130);
}

.save-button-dashboard {
    background-color: transparent;
    border: 1px solid black;
    color: black;
    border-radius: 35px;
    margin-bottom: 10px;
    box-shadow: none;
    min-width: 100px;
}

.dark-save-button-dashboard {
    background-color: transparent;
    border: 1px solid white;
    color: white;
    border-radius: 35px;
    margin-bottom: 10px;
    box-shadow: none;
    min-width: 100px;
}


.dark-save-button-dashboard:disabled {
    border-color: rgb(180, 170, 130);
    color: rgb(180, 170, 130);
    cursor: not-allowed;
}

.dark-update-button-modal-dashboard:disabled {
    border-color: rgb(180, 170, 130);
    color: rgb(180, 170, 130);
    cursor: not-allowed;
}

.change-password-button {
    background-color: transparent;
    color: black;
    border: 1px solid black;
    border-radius: 35px;
    min-width: 100px;
}

.change-password-button:hover {
    background-color: black;
    color: white;
}

.dark-change-password-button {
    background-color: transparent;
    color: white;
    border: 1px solid white;
    border-radius: 35px;
    min-width: 100px;
}

.dark-change-password-button:hover {
    background-color: white;
    color: black;
}

.save-button-dashboard:hover {
    color: white;
    background-color: black;
    box-shadow: none;
}

.dark-save-button-dashboard:hover {
    color: black;
    background-color: white;
    box-shadow: none;
}

.close-modal-button-dashboard {
    background-color: transparent;
    border: 1px solid black;
    color: black;
    border-radius: 35px;
    box-shadow: none;
    margin-bottom: 10px;
    min-width: 100px;
}

.close-modal-button-dashboard:hover {
    color: white;
    background-color: black;
}

.dark-close-modal-button-dashboard {
    background-color: transparent;
    border: 1px solid white;
    color: white;
    border-radius: 35px;
    box-shadow: none;
    margin-bottom: 10px;
    min-width: 100px;
}

.dark-close-modal-button-dashboard:hover {
    color: black;
    background-color: white;
}

.delete-modal-button-dashboard {
    background-color: transparent;
    color: black;
    border: 1px solid black;
    border-radius: 35px;
    margin-bottom: 10px;
    min-width: 100px;
}

.dark-delete-modal-button-dashboard {
    background-color: transparent;
    color: white;
    border: 1px solid white;
    border-radius: 35px;
    margin-bottom: 10px;
    min-width: 100px;
}

.dark-delete-modal-button-dashboard:hover {
    background-color: white;
    color: black;
}

.edit-button-dashboard {
    background-color: transparent;
    border: 1px solid black;
    color: black;
    border-radius: 35px;
    min-width: 80px;
}

.edit-button-dashboard:hover {
    color: white;
    background-color: black;
}

.edit-button-dashboard:disabled {
    color: rgba(0, 0, 0, 0.9);
}

.dark-edit-button-dashboard {
    background-color: transparent;
    border: 1px solid white;
    color: white;
    border-radius: 35px;
    min-width: 80px;
}

.dark-edit-button-dashboard:disabled {
    color: rgba(255, 255, 255, 0.9);
}

.dark-edit-button-dashboard:hover {
    color: black;
    background-color: white;
}

.delete-button-dashboard {
    background-color: transparent;
    color: black;
    border: 1px solid black;
    border-radius: 35px;
    min-width: 80px;
}

.delete-button-dashboard:hover {
    background-color: black;
    color: white;
}

.dark-delete-button-dashboard {
    background-color: transparent;
    color: white;
    border: 1px solid white;
    border-radius: 35px;
    min-width: 80px;
}

.dark-delete-button-dashboard:hover {
    background-color: white;
    color: black;
}

.delete-modal-button-dashboard:hover {
    background-color: black;
    color: white;
    box-shadow: none;
}

.delete-file-button {
    color: black;
    border: 1px solid black;
    border-radius: 35px;
    margin-right: 16px;
}

.delete-file-button:hover {
    color: white;
    background-color: black;
}

.dark-delete-file-button {
    color: white;
    border: 1px solid white;
    border-radius: 35px;
    margin-right: 16px;
}

.dark-delete-file-button:hover {
    color: black;
    background-color: white;
}

.download-file-button {
    color: black;
    border: 1px solid black;
    border-radius: 35px;
    margin-right: 16px;
}

.download-file-button:hover {
    color: white;
    background-color: black;
}

.dark-download-file-button {
    color: white;
    border: 1px solid white;
    border-radius: 35px;
    margin-right: 16px;
}

.dark-download-file-button:hover {
    color: black;
    background-color: white;
}

.card-subtitle {
    font-size: 25px;
    color: rgb(85, 85, 85) !important;
    margin-bottom: 24px !important;
    margin-top: 20px !important;
}

.dark-card-subtitle {
    font-size: 25px;
    color: rgb(238, 238, 238) !important;
    margin-bottom: 24px !important;
    margin-top: 20px !important;
}

.select-title {
    font-size: 25px;
    color: rgb(85, 85, 85) !important;
}

.dark-select-title {
    font-size: 25px;
    color: rgb(238, 238, 238) !important;
}

.css-75lo0f-MuiPaper-root {
    margin-top: 16px;
}

.css-gff60v-MuiPaper-root {
    margin-top: 16px;
}

.connection-dashboard-buttons {
    color: rgb(85, 85, 85);
    font-size: 18px;
}

.connection-dashboard-buttons:hover {
    color: rgb(180, 170, 130);
}

.dark-connection-dashboard-buttons {
    color: rgb(238, 238, 238);
    font-size: 18px;
}

.dark-connection-dashboard-buttons:hover {
    color: rgb(180, 170, 130);
}

.css-14qh0u1-MuiOutlinedInput-notchedOutline {
    border-width: 0;
}

.css-3c5vfx-MuiInputBase-root-MuiOutlinedInput-root {
    border-radius: 35px !important;
}

.MuiSwitch-root {
    color: #ccc; /* Change the color of the switch when it's off */
}

.MuiSwitch-thumb {
    background-color: rgb(85, 85, 85); /* Change the color of the thumb when the switch is off */
}

.MuiSwitch-track {
    background-color: rgb(85, 85, 85); /* Change the color of the track when the switch is off */
}

.MuiSwitch-root input[type='checkbox']:checked + .MuiSwitch-track {
    background-color: #ccc; /* Change the color of the track when the switch is on */
}

.MuiSwitch-root input[type='checkbox']:checked + .MuiSwitch-thumb {
    background-color: #ccc; /* Change the color of the thumb when the switch is on */
}

.css-1n5bjej-MuiGrid-root > .MuiGrid-item {
    padding-top: 50px !important;
}

.css-1rk6x9c-MuiPaper-root-MuiCard-root:after {
    background-color: rgb(238, 238, 238) !important;
}

.css-wbxuj3-MuiPaper-root-MuiCard-root:before {
    display: none !important;
}

.css-wbxuj3-MuiPaper-root-MuiCard-root:after {
    display: none !important;
}

.css-1f2siyk-MuiButtonBase-root-MuiChip-root:hover {
    background: rgb(85, 85, 85) !important;
    background-color: rgb(85, 85, 85) !important;
}

.css-6kpjg9-MuiPaper-root-MuiCard-root:before {
    border-color: rgb(238, 238, 238) !important;
}

.css-6kpjg9-MuiPaper-root-MuiCard-root:after {
    border-color: rgb(238, 238, 238) !important;
}

.css-1n53ba5-MuiPaper-root-MuiCard-root:after {
    background: rgb(238, 238, 238) !important;
}

.css-w80tb3-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-w80tb3-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-w80tb3-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
    color: rgb(85, 85, 85);
}

.css-mf6ueb-MuiTablePagination-root .MuiTablePagination-selectLabel {
    margin-bottom: 0 !important;
}

.MuiTablePagination-selectLabel {
    margin-bottom: 0 !important;
}

p.MuiTablePagination-displayedRows .css-2epr5z-MuiTablePagination-displayedRows {
    margin-bottom: 0 !important;
}

.css-2epr5z-MuiTablePagination-displayedRows {
    margin-bottom: 0 !important;
}

.MuiTablePagination-displayedRows {
    margin-bottom: 0 !important;
}

p.MuiTablePagination-displayedRows {
    margin-bottom: 0 !important;
}

.image-dahsboard-content {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 0;
    background: rgba(0, 0, 0, 0.4);
    width: 100%;
    transition: 1s ease;
}

.image-dashboard-content-actions {
    position: relative;
    bottom: -5%;
}

.card-images-dahsboard-card:hover .image-dahsboard-content {
    height: 100%;
    border-radius: 12px;
}

.update-button-modal-dashboard {
    color: black;
    border: 1px solid black;
    border-radius: 35px;
    min-width: 100px;
}

.dark-update-button-modal-dashboard {
    color: white;
    border: 1px solid white;
    border-radius: 35px;
    min-width: 100px;
}

.dark-update-button-modal-dashboard:hover {
    color: black;
    background-color: white;
}

.download-image-button {
    border: 1px solid white;
    color: white;
    border-radius: 35px;
    min-width: 80px;
}

.download-image-button:hover {
    background: white;
    color: black;
}

.delete-image-button {
    border: 1px solid white;
    color: white;
    border-radius: 35px;
    min-width: 80px;
}

.delete-image-button:hover {
    background: white;
    color: black;
}

.apexcharts-toolbar {
    right: 0 !important;
    left: 10px !important;
    direction: rtl;
}

.apexcharts-zoom-icon {
    display: none !important;
}

.ps__thumb-y {
    display: none !important;
}

.ps__thumb-x {
    display: none !important;
}

.modal-title-dashboard {
    color: rgb(85, 85, 85);
}

.dark-modal-title-dashboard {
    color: rgb(238, 238, 238);
}

.list-item-dashboard {
    background-color: rgb(238, 238, 238);
}

.dark-list-item-dashboard {
    background-color: rgb(15, 15, 15);
}

.list-link-item-dashboard {
    background-color: rgb(238, 238, 238);
}

.dark-link-list-item-dashboard {
    background-color: rgb(15, 15, 15);
}

.add-link-button {
    background-color: white;
}

.dark-add-link-button {
    background-color: white;
    color: rgb(15, 15, 15);
}

.dark-add-link-button:hover {
    background-color: rgb(15, 15, 15);
    color: white;
}

.css-edb200-MuiTablePagination-selectLabel {
    margin-bottom: 0 !important;
}

.css-nzvk00-MuiTablePagination-displayedRows {
    margin-bottom: 0 !important;
}

.css-l3udfn-MuiPaper-root {
    margin-top: 16px;
}
// .css-4solin-MuiPaper-root-MuiCard-root:after {
//   display: none !important;
// }

// .css-14qh0u1-MuiOutlinedInput-notchedOutline:hover, .css-14qh0u1-MuiOutlinedInput-notchedOutline:focus {
//   border-width: 1px !important;
// }

// .count-in-widget {
//   font-size: calc(48px - 2px * (10 - min(10, 5)));
// }
.card-tab-item {
    border-radius: 12px;
    transition: 0.5s all ease-out;
    margin-bottom: 0.5rem;
}
.card-tab-item:hover {
    background-color: rgb(238, 238, 238);
}
.card-tab-item.Mui-selected {
    background-color: rgb(238, 238, 238);
    border: 1px solid rgb(180, 170, 130);
}

.dark-card-tab-item {
    border-radius: 12px;
    transition: 0.5s all ease-out;
    margin-bottom: 0.2rem;
}
.dark-card-tab-item:hover {
    background-color: rgb(15, 15, 15);
}
.dark-card-tab-item.Mui-selected {
    background-color: rgb(15, 15, 15);
    border: 1px solid rgb(180, 170, 130);
}

.download-qr-code-box {
    display: flex;
    align-items: center;
    position: relative;
    top: 36%
}

.qr-code-box {
    display: flex;
    align-items: center;
    position: relative;
    top: 39%;
    transform: translateY(-50%);
}

.live-qr-preview-box {
    display: flex;
    align-items: center;
    position: relative;
    top: 0%;
}

.save-button-dashboard-stack {
    margin-right: 3rem;
}

.card-images-dashboard-card-media {
    transition: 0.5s all;
}

// .card-images-dahsboard-card {
//     transition: all 0.5s;
// }

// .card-images-dahsboard-card:hover {
//     transform: scale(1.01);
// }

.card-images-dahsboard-card:hover .card-images-dashboard-card-media {
    transform: scale(1.1);
}

.links-list-dasboard {
    padding-right: 2rem;
}

.about-profile-picture-dashboard-stack {
    margin-right: 32px;
}

.about-cover-picture-dashboard-stack {
    margin-right: 32px;
}

.about-logo-picture-dashboard-stack {
    margin-left: 150px;
}

.company-logo-form-label {
    width: max-content;
}

.card-tabs-dashboard {
    margin-bottom: 1rem !important;
}

.dashboard-pagination-arrow {
    font-size: 35px;
    margin: 0.5rem;
    cursor: pointer;
    transition: 0.5s all;
}

.dashboard-pagination-arrow:hover {
    transform: scale(1.2);
    color: rgb(180, 170, 130);
}

// .card-lookup-card {
//     // height: 320px;
//     height: 320px;
//     overflow: hidden;
//     transition: height 1s ease;}

// .card-lookup-card:hover {
//     height: 400px;
// }

// .card-actions-lookup {
//     height: 0;
//     transform: scale(0);
//     transition: 1s all;
// }

// .card-lookup-card:hover .card-actions-lookup {
//     height: 20px;
//     transform: scale(1);
// }

.card-lookup-card {
    transition: 0.5s all;
    height: 100%;
}

// .card-lookup-card:hover {
//     transform: scale(0.9);
// }

.active-device-card {
    transition: 0.5s all;
}

.active-device-card:hover {
    transform: scale(0.9);
}

// .card-actions-lookup {
//     height: 0;
//     overflow: hidden;
//     transition: 1s all;
// }

// .card-lookup-card:hover .card-actions-lookup {
//     height: 60px;
// }

// .card-lookup-card-cover-photo {
//     object-fit: cover;
//     height: 50%;
//     width: 100%;
//     clip-path: inset(50% 0 0 0);
//     transition: 1s all;
// img:hover {
//     clip-path: inset(0 0 0 0);
// }
// }

#dashboard-list-item-text > span {
    font-size: 14px !important;
}

.MuiListItemText-primary {
    font-size: 14px !important;
}

.dashboard-list-item-text-class {
    display: inline;
}

.dashboard-list-item-text-class span {
    display: inline;
    margin-left: 10px;
}

.dashboard-list-item-icon-class {
    display: inline;
}

.dashboard-list-item-text-group-class span{
    position: relative;
    top: 2px;
}

@media(min-width: 1000px) {
    .card-videos-dashboard-container {
        max-height: 475px;
    }
}

@media(min-width: 1000px) {
    .card-images-dashboard-container {
        max-height: 475px;
    }
}

@media (max-width: 786px) {
    .card-videos-dashboard-container {
        max-height: 100%;
    }

    .card-images-dashboard-container {
        max-height: 100%;
    }

    .before-preview-devider {
        display: none;
    }
    
    .qr-code-after-form {
        order: 1
    }

    .form-before-qr-code {
        margin-left: 1rem;
        order: 2;
    }

    .card-subtitle, .dark-card-subtitle {
        margin-left: 1rem;
        margin-right: 1rem;
        font-size: 20px;
    }


    .download-qr-code-box {
        margin-bottom: 3rem;
        top: 10%;
    }

    .qr-code-box {
        top: 22.5%;
    }

    .live-qr-preview-box {
        margin-top: 3rem;
        top: -16%;
    }

    .about-form-container {
        padding-left: 1rem;
    }

    .videos-grid-container {
        padding-left: 1rem;
    }

    .select-group-card-settings {
        max-width: 150px;
    }

    .save-button-dashboard-stack {
        margin-right: 0;
    }

    .card-images-grid-dashboard {
        padding-left: 16px;
    }

    .links-list-dasboard {
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .files-list-dasboard {
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .about-profile-picture-dashboard-div {
        justify-content: center;
    }

    .about-profile-picture-dashboard-stack {
        margin-right: 0;
    }

    .about-cover-picture-dashboard-stack {
        margin-right: 16px;
    }

    .about-logo-picture-dashboard-stack {
        margin-left: 0;
    }

    .company-logo-form-label {
        width: 100%;
    }

    .dashboard-list-item-text-class {
        display: inline;
    }
    
    .dashboard-list-item-text-class span {
        display: inline;
        margin-left: 10px;
    }
    
    .dashboard-list-item-icon-class {
        display: inline;
    }

    .group-icon-text-grid {
        margin-bottom: 10px;
    }

    .files-text-icon-grid {
        margin-bottom: 10px;
    }

    .select-title {
        font-size: 20px;
        margin-top: 10px;
    }
    
    .dark-select-title {
        font-size: 20px;
        margin-top: 10px;
    }

    .profile-picture-about-dashboard-stack {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 10px;
    }

    .company-logo-about-dashboard-stack {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 10px;
        margin-left: 0;
    }
}


.dashboard-about-profile-grand-upper, .dashboard-links-profile-grand-upper, .dashboard-videos-profile-grand-upper {
    width: 500px;
    height: 500px;
}

.dashboard-about-profile-parent-upper {
    margin-left: 10px;
    margin-right: 10px;
    margin-top: -190px;
    width: 400px;
    transform: scale(0.85);
}

.dashboard-links-profile-parent-upper {
    margin-left: 10px;
    margin-right: 10px;
    margin-top: -100px;
    width: 400px;
    transform: scale(0.85);
}

.dashboard-videos-profile-parent-upper {
    margin-left: 10px;
    margin-right: 10px;
    margin-top: -130px;
    width: 400px;
    transform: scale(0.85);
}

.videos-content-padding {
    padding: 8px;
}

.fslightbox-fade-in img {
    max-width: 100%;
    height: auto !important;
}


@media (max-width: 786px) {
    .dashboard-about-profile-grand-upper {
        width: 150%;
        display: flex;
        justify-content: center;
        max-height: 500px;
    }

    .dashboard-links-profile-grand-upper {
        width: 150%;
        display: flex;
        justify-content: center;
        max-height: fit-content;
    }
    
    .dashboard-about-profile-parent-upper {
        margin-left: 10px;
        margin-right: 10px;
        margin-top: -100px;
        width: 500px;
        transform: scale(0.6);
    }

    .dashboard-links-profile-parent-upper {
        margin-left: 25px;
        margin-top: -110px;
        width: 500px;
        transform: scale(0.6);
    }

    .dashboard-videos-profile-grand-upper {
        margin-left: 10px;
        margin-right: 10px;
        margin-top: -25px;
        transform: scale(0.85);
    }

    .dashboard-images-profile-grand-upper {
        margin-left: 7%;
    }

    .dashboard-connection-paper {
        height: 75% !important;
        margin-bottom: 100px;
    }

    .dashbaord-profile-preview-grid {
        min-height: 625px !important;
    }
}

@media (max-width: 1400px) and (min-width: 1200px) {
    .videos-content-padding {
        padding: 2px;
    }
}


.dashboard-connection-paper {
    height: 85%;
}

.dark-files-links-dashboard-grid::-webkit-scrollbar {
    width: 12px;
}

.dark-files-links-dashboard-grid::-webkit-scrollbar-track {
    background-color: rgb(85, 85, 85);
    border-radius: 30px;
  }
  
  .dark-files-links-dashboard-grid::-webkit-scrollbar-thumb {
    background-color: rgb(15, 15, 15);
    border-radius: 30px;
  }


  .files-links-dashboard-grid::-webkit-scrollbar {
    width: 12px;
}

.files-links-dashboard-grid::-webkit-scrollbar-track {
    background-color: rgb(180, 180, 180);
    border-radius: 30px;
  }
  
  .files-links-dashboard-grid::-webkit-scrollbar-thumb {
    background-color: rgb(230, 230, 230);
    border-radius: 30px;
  }


.files-links-dashboard-box {
    max-height: 725px;
}

.groups-dashboard-buttons-stack {
    justify-content: end;
}

@media (max-width: 786px) {
    .files-links-dashboard-box {
        max-height: 1200px;
    }

    .groups-dashboard-buttons-stack {
        justify-content: space-around;
    }
}


.add-member-dialouge-textfield:-webkit-autofill {
    border-radius: 35px !important;
}

.MuiOutlinedInput-input:-webkit-autofill {
    border-radius: 35px !important;
}

input:-webkit-autofill {
    border-radius: 35px !important;
}

